import {createSlice} from "@reduxjs/toolkit";
import {IInitialCasesSlice} from "../../../ts/interfaces/interfaces";

const initialState = {
    appealCategory: "Выберите тему вашего обращения",
};

const contactWithUsSlice = createSlice({
        name: "contactWithUs",
        initialState,
        reducers: {
            setAppealCategory: (state, action) => {state.appealCategory = action.payload},
        }

})
const {reducer, actions} = contactWithUsSlice

export default reducer
export const {setAppealCategory} = actions;