import {createSlice} from "@reduxjs/toolkit";

const initialState = {menuState: "inactive"};

const menuSlice = createSlice({
    name: "menu",
    initialState,
    reducers: {
        menuActive: state => {state.menuState = "active"},
        menuInactive: state => {state.menuState = "inactive"}
    }
});

const {reducer, actions} = menuSlice;

export default reducer;
export const {menuActive, menuInactive} = actions;