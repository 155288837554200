import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    sectionName: "",
    currentSection: "",
    eventAgencyContent: "",
    content: ""
};

const awardsSlice = createSlice({
    name: "eventAgency",
    initialState,
    reducers: {
        setSectionName: (state, action) => {
            state.sectionName = action.payload
        },
        setCurrentSection: (state, action) => {
            state.currentSection = action.payload
        },
        setContent: (state, action) => {
            state.content = action.payload
        }
    }
});

const {reducer, actions} = awardsSlice;

export default reducer;
export const {setSectionName, setCurrentSection, setContent} = actions;