import {configureStore, combineReducers} from "@reduxjs/toolkit";
import menu from "../components/common-components/menu/menu-slice";
import awards from "../components/common-components/awards/awards-slice";
import pages from "../components/pages/pages-slice";
import cases from "../components/pages/event-agency-information/information-modules/cases/cases-slice";
import eventAgency from "../components/pages/event-agency-information/event-agency-slice";
import project from "../components/pages/event-agency-information/information-modules/project/project-slice";
import whiteModal from "../components/common-components/white-modal/white-modal-slice";
import contactWithUs from "../components/common-components/contact-with-us-module/contact-with-us-slice";

const rootReducer = combineReducers({
    menu,
    awards,
    pages,
    cases,
    eventAgency,
    project,
    whiteModal,
    contactWithUs
})

const store = configureStore({
    reducer: rootReducer,
    devTools: true
})

export type RootState = ReturnType<typeof rootReducer>

export default store