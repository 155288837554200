import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    whiteModalState: false,
    whiteModalContent: {
        img: "",
        desc: ""
    }
}

const whiteModalSlice = createSlice({
    name: "whiteModal",
    initialState,
    reducers: {
        setWhiteModalState: (state, action) => { state.whiteModalState = action.payload },
        setWhiteModalContent: (state, action) => { state.whiteModalContent = action.payload }
    }
})

const {reducer, actions} = whiteModalSlice;

export default reducer;

export const {setWhiteModalState, setWhiteModalContent} = actions