import {createSlice} from "@reduxjs/toolkit";
import {IInitialCasesSlice} from "../../../../../ts/interfaces/interfaces";

const initialState: IInitialCasesSlice = {
    casesType: "",
    casesList: [],
    chosenCategory: ""
};

const casesSlice = createSlice({
    name: "cases",
    initialState,
    reducers: {
        setCasesType: (state, action) => {state.casesType = action.payload},
        setCasesList: (state, action) => {state.casesList = action.payload},
        setChosenCategory: (state, action) => {state.chosenCategory = action.payload},
    }
})

const {reducer, actions} = casesSlice;

export default reducer;
export const {setCasesType, setCasesList, setChosenCategory} = actions;