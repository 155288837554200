import {createSlice} from "@reduxjs/toolkit";

const initialState = {awardsState: "awards-closed"};

const awardsSlice = createSlice({
    name: "awards",
    initialState,
    reducers: {
        awardsOpened: state => {state.awardsState = "awards-opened"},
        awardsClosed: state => {state.awardsState = "awards-closed"},
    }
});

const {reducer, actions} = awardsSlice;

export default reducer;
export const {awardsOpened, awardsClosed} = actions;