import React, {Suspense, lazy, useState, useEffect, FC} from "react";
import {Switch, Route} from "react-router-dom";
import './app.scss';

const MainPage = lazy(()=> import("../pages/main-page/main"));
const EventAgency = lazy(()=> import("../pages/event-agency-information/event-agency-information"));
const Menu = lazy(()=> import("../common-components/menu/menu"));

const App: FC = () => {
    useWindowSize();
  return (
        <div className="app">
            <Switch>
                <Route path='/' exact component = {()=>(
                    <Suspense fallback={<div/>}>
                        <MainPage/>
                    </Suspense>
                )}/>
                <Route path = "/event-agency" component = {()=>(
                    <Suspense fallback={<div/>}>
                        <EventAgency/>
                    </Suspense>
                )}/>
            </Switch>
            <Suspense fallback={<div/>}>
                <Menu/>
            </Suspense>
        </div>
  );
}
const useWindowSize = () => {
    const [lastWindowSize, setLastWindowSize] = useState(window.innerWidth);
    const [currentWindowWidth, setCurrentWindowWidth] = useState(window.innerWidth)
    useEffect(() => {
        const handleResize = () => {
            setCurrentWindowWidth(window.innerWidth);
            if (lastWindowSize !== currentWindowWidth) {
                setLastWindowSize(windowSize => (window.innerWidth));
                setCurrentWindowWidth(currentWindowWidth => lastWindowSize);
            }
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return currentWindowWidth;
}
export default App;

