import {createSlice} from "@reduxjs/toolkit";

const initialState = {currentPage: "main-page"}

const pagesSlice = createSlice({
    name: "pages",
    initialState,
    reducers: {
        changeCurrentPage: (state,action) => {state.currentPage = action.payload}
    }
});

const {reducer, actions} = pagesSlice;

export default reducer;

export const {changeCurrentPage} = actions;