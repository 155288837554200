import {createSlice} from "@reduxjs/toolkit";
import {IInitialProjectSlice} from "../../../../../ts/interfaces/interfaces";

const initialState: IInitialProjectSlice = {
    chosenCase: {
        id:"",
        type: "",
        name: "",
        format: "",
        location: "",
        photos: [],
        goals: []
    },
    caseIndex: 0
};

const projectSlice = createSlice({
    name: "eventAgency",
    initialState,
    reducers: {
        setChosenCase: (state,action) => {state.chosenCase = action.payload},
        setCaseIndex: (state, action) => {state.caseIndex = action.payload}
    }
});

const {reducer, actions} = projectSlice;

export default reducer;
export const {setChosenCase, setCaseIndex} = actions;